











import Vue from 'vue';

import { AreaWithRoomNames } from '@/interfaces/areas/AreaWithRoomNames';

export default Vue.extend({
  name: 'AreaRow',
  props: {
    area: {
      type: Object as () => AreaWithRoomNames,
      required: true,
    },
  },
  computed: {
    roomsList(): string {
      const roomNames = this.area.rooms.length > 0
        ? this.area.rooms.map((room) => room.name).join(', ')
        : 'No rooms';
      return `Rooms (${this.area.rooms.length}): ${roomNames}`;
    },
  },
});
