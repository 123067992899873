






























import Vue from 'vue';

import CloseButton from '@/components/buttons/CloseButton.vue';

import { AreaWithRoomNames } from '@/interfaces/areas/AreaWithRoomNames';

export default Vue.extend({
  name: 'ControlModeSelector',
  components: {
    CloseButton,
  },
  props: {
    area: {
      type: Object as () => AreaWithRoomNames,
      required: true,
    },
  },
});
