import gql from "graphql-tag";

export namespace Buildings {

  export const query = gql`
    {
      buildings {
        id
        name
      }
    }
  `;

  export interface ContentDefinition {
    id: string;
    name: string;
  }

  export interface ResultDefinition {
    buildings: ContentDefinition[];
  }

}
