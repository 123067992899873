import gql from "graphql-tag";

export namespace BuildingTopologyWithRoomNames {

  export const query = gql`
    query buildingTopology($buildingId: String!) {
      buildingTopology(buildingId: $buildingId) {
        id
        name
        areas: floors {
          id
          name
          rooms {
            id
            name
          }
        }
      }
    }
  `;

  export interface VariablesDefinition {
    buildingId: string;
  }

  export interface ContentDefinition {
    id: string;
    name: string;
    areas: {
      id: string;
      name: string;
      rooms: {
        id: string;
        name: string;
      }[];
    }[];
  }

  export interface ResultDefinition {
    buildingTopology: ContentDefinition;
  }

}
