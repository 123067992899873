





























import Vue from 'vue';
import first from 'lodash/first';

import BackButtonMobile from '@/components/buttons/BackButtonMobile.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import AreaRow from '@/components/areas/AreaRow.vue';
import ControlModeSelector from '@/components/areas/ControlModeSelector.vue';

import { Buildings } from '@/graphqlRequests/queries/Buildings';
import { BuildingTopologyWithRoomNames } from '@/graphqlRequests/queries/BuildingTopologyWithRoomNames';
import { generateKey } from '@/helpers/StringGenerator';
import { AreaWithRoomNames } from '@/interfaces/areas/AreaWithRoomNames';
import { ControlMode } from '@/enums/ControlMode';

export default Vue.extend({
  name: 'AreaSelector',
  components: {
    BackButtonMobile,
    BackButton,
    AreaRow,
    ControlModeSelector,
  },
  data(): {
    buildings: Buildings.ContentDefinition[];
    buildingTopology: BuildingTopologyWithRoomNames.ContentDefinition | undefined;
    selectedArea: AreaWithRoomNames | undefined;
  } {
    return {
      buildings: [],
      buildingTopology: undefined,
      selectedArea: undefined,
    };
  },
  watch: {
    building(newValue: Buildings.ContentDefinition | undefined) {
      if (newValue != undefined) {
        this.$store.commit('setSelectedBuildingId', newValue.id);
      }
    },
  },
  apollo: {
    buildings: {
      query: Buildings.query,
      watchLoading(isLoading) {
        if (isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingsLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingsLoadingKey);
        }
      },
    },
    buildingTopology: {
      skip(): boolean {
        return this.building == undefined;
      },
      query: BuildingTopologyWithRoomNames.query,
      variables(): BuildingTopologyWithRoomNames.VariablesDefinition {
        return {
          buildingId: this.building!.id,
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingTopologyLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingTopologyLoadingKey);
        }
      },
    },
  },
  computed: {
    buildingsLoadingKey(): string {
      return generateKey();
    },
    buildingTopologyLoadingKey(): string {
      return generateKey();
    },
    building(): Buildings.ContentDefinition | undefined {
      return first(this.buildings);
    },
    buildingName(): string | undefined {
      return this.building?.name;
    },
    areas(): AreaWithRoomNames[] {
      return this.buildingTopology?.areas || [];
    }
  },
  methods: {
    back() {
      this.$store.commit('dropState');
      this.$router.push({ name: 'Start', });
    },
    selectArea(area: AreaWithRoomNames | undefined) {
      window.setTimeout(
        () => this.selectedArea = area,
        100,
      );
    },
    selectAreaMode() {
      if (this.selectedArea == undefined) {
        return;
      }
      this.$store.commit('setSelectedAreaId', this.selectedArea.id);
      this.$store.commit('setControlMode', ControlMode.AREA);
      this.selectedArea = undefined;
      window.setTimeout(
        () => this.$router.push({ name: 'ScenesSelector', }),
        100,
      );
    },
    selectRoomMode() {
      if (this.selectedArea == undefined) {
        return;
      }
      this.$store.commit('setSelectedAreaId', this.selectedArea.id);
      this.$store.commit('setControlMode', ControlMode.ROOM);
      this.selectedArea = undefined;
      window.setTimeout(
        () => this.$router.push({ name: 'RoomSelector', }),
        100,
      );
    },
  },
});
